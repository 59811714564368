/* js/datPickerTest.js */
/*
$('.datepicker').pickadate();
$('.datepicker-start').pickadate();
$('.datepicker-end').pickadate();*/

$('.datepicker:not(.no-time), .datepicker-start:not(.no-time), .datepicker-end:not(.no-time)').datetimepicker({
    format: "yyyy-mm-dd hh:ii:ss",
    autoclose: true,
    todayBtn: true,
    minuteStep: 10
});
$('.datepicker.no-time, .datepicker-start.no-time, .datepicker-end.no-time').datetimepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    todayBtn: true,
    minView:2
});