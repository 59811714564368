


jQuery( document ).ready(function( $ ) {


    $(window).on('load', function () {
        $('.appToast').addClass('appToast-active').delay(2500)
            .queue(function () {
                $(this).removeClass("appToast-active");
                $(this).dequeue();
            });
    });


    $('.trigger').click(function () {
        $('.appToast').addClass('appToast-active').delay(2500)
            .queue(function () {
                $(this).removeClass("appToast-active");
                $(this).dequeue();
            });
    });

});





