/* js/appTimer.js */

jQuery( document ).ready(function( $ ) {

    $('.js-timerTrigger').click(function () {
       $(this).css('display', 'none');
       $('.btnStack').css('display','block');
    });

    $('.js-timerActionsClose').click(function () {
       $('.js-timerTrigger').css('display', 'inline-block');
       $('.btnStack').css('display','none');
    });



});