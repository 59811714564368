/* js/rangeTest.js */


$('input[type="range"]').rangeslider({
    polyfill : false,
    onInit : function() {
        
        
        this.output = $( '<div class="range-output" />' ).insertAfter( this.$range ).html( this.$element.val() );
    },
    onSlide : function( position, value ) {
        this.output.html( value );
    }
});