/* js/proTest2.js */


  $('.test').circleProgress({
    value: 0.75,
    fill: {
      gradient: ["red", "orange"]
    }
  }).on('circle-animation-progress', function(event, progress, stepValue) {
    $(this).find('strong').text(stepValue.toFixed(2).substr(1));
  });


  $('.new').circleProgress({
    size: 40,
    fill: {
      gradient: ["#00aff0", "#00f078"]
    }
  }).on('circle-animation-progress', function(event, progress, stepValue) {
    $(this).find('strong').text(stepValue.toFixed(2).substr(1));
  });
  
$('.circle-light').circleProgress({
    value: 0.75,
    size: 150,
    fill: {
      gradient: ["#fff", "#fff"]
    }
  }).on('circle-animation-progress', function(event, progress, stepValue) {
    $(this).find('strong').text(stepValue.toFixed(2).substr(1));
  });