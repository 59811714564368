/* js/appBar.js */


jQuery(document).ready(function ($) {


    $('.js-navBoxTrigger').click(function () {
        $('body').toggleClass('nav-is-open');
    });


    $(document).mouseup(function (e) {
        var container = $('.appBar');

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $('body').removeClass('nav-is-open');
        }
    });


});