/* js/view-frame.js */

jQuery(document).ready(function ($) {


    $('.js_viewOpen').click(function(e){
        e.preventDefault();


        $('.view-ajax-content').load($(this).prop("href"));


        $('body').addClass('viewDetails-open');
    });

    $('.js_viewClose').click(function(e){
        e.preventDefault();
        $('body').removeClass('viewDetails-open');
    });

});






$(function() {
      //Enable swiping...
      $(".viewFrame__details").swipe( {
        //Generic swipe handler for all directions
        swipeRight:function(event, direction, distance, duration, fingerCount) {
          $('body').removeClass('viewDetails-open');
        },
        //Default is 75px, set to 0 for demo so any distance triggers swipe
         threshold:75
      });


      $(".js_viewOpen").swipe( {
        //Generic swipe handler for all directions
        swipeLeft:function(event, direction, distance, duration, fingerCount) {
          $('body').addClass('viewDetails-open');
        },
        //Default is 75px, set to 0 for demo so any distance triggers swipe
         threshold:75
      });

});

