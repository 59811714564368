jQuery(document).ready(function ($) {



    var winWidth = $( 'body' ).width();


    $('.underlay').css('max-width', winWidth);







    $('.mh-item').matchHeight();

    $('.controlBox__title').matchHeight();


    $('.appRow__item').matchHeight();




    // Makes sure all AJAX calls have the token
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // Sets up the timer (big top of the page timer)
    var $timer = $('.timer:not(.static)');
    if($timer.length>0) {
        var $hour=$timer.find('input[name=hour]');
        var $minute=$timer.find('input[name=minute]');
        var $second=$timer.find('input[name=second]');

        window.setInterval(function(){
            if(!$timer.hasClass('static')) { // allows us to pause the timer
                var sec = Number($second.val());
                var min = Number($minute.val());
                var hour = Number($hour.val());
                sec++;
                if(sec==60) {sec=0; min++;}
                if(min==60) {min=0; hour++;}
                $hour.val($.trim(hour).length === 1 ? '0' + hour : hour);
                $minute.val($.trim(min).length === 1 ? '0' + min : min);
                $second.val($.trim(sec).length === 1 ? '0' + sec : sec);
            }
        }, 1000);
    }


    // stops the timer if the numbers are changed manually (might not be something we want, but ¯\_(ツ)_/¯ )
    $('.timer').on('change',':input',function(){
        $('.timer').removeClass('static').addClass('static');
    });
    $('.appForm').on('click','button[type=submit]',function(e){
        e.preventDefault();
        $(this).closest('.appForm').attr('action',$(this).data('action')).submit();
    });

    $('.confirm-action').on('click',function(e){
        if(!confirm($(this).data('confirm'))) e.preventDefault();
    });


});



if(window.location.href.indexOf('api/fitbit/auth')>=0 && window.location.hash!='') {
    window.location.replace(window.location.origin+window.location.pathname+'?'+window.location.hash.substring(1));
}