/* js/appModal.js */

jQuery( document ).ready(function( $ ) {

    $('.js-modalTrigger').click(function () {
        $('body').addClass('modal-is-open');
    });



    $(document).mouseup(function (e)
    {
        var container = $('.appModal__content');

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $('body').removeClass('modal-is-open');
        }
    });



});